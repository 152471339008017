import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";

const Fact = ({darkMode = true}) => {
  let extraClasses = "";
  if (darkMode) {
    extraClasses = "bg-blackish-blue dark-mode-texts border-top border-gray-4";
  } else {
    extraClasses = "border-bottom border-top border-gray-4";
  }

  return (
    <>
      {/* <!-- Fact Area --> */}
      <div className={"fact-section py-12 pt-lg-20 pb-lg-17 " + extraClasses}>
        <Container>
          <Row className="justify-content-center mb-n9">
            <Col sm="6" md="6" lg="4" className="mb-9">
              <div className="single-fact d-flex px-9 px-sm-0 px-xl-9">
                <h3 className="gr-text-3 mr-9">1M+</h3>
                <p className="gr-text-9 mb-0">
                  Shifts scheduled every month
                </p>
              </div>
            </Col>
            <Col sm="6" md="6" lg="4" className="mb-9">
              <div className="single-fact d-flex px-9 px-sm-0 px-xl-9">
                <h3 className="gr-text-3 mr-9">
                  <LazyLoad>
                    <span className="counter">
                      <CountUp duration={2} end={95} />%
                    </span>
                  </LazyLoad>
                </h3>
                <p className="gr-text-9 mb-0">
                  Increase in workflow efficiency
                </p>
              </div>
            </Col>
            <Col sm="6" md="6" lg="4" className="mb-9">
              <div className="single-fact d-flex px-9 px-sm-0 px-xl-9">
                <h3 className="gr-text-3 mr-9">
                  <LazyLoad>
                    <span className="counter">
                      <CountUp
                        duration={2}
                        decimal="."
                        decimals={1}
                        end={8.5}
                      />
                    </span>
                  </LazyLoad>
                </h3>
                <p className="gr-text-9 mb-0">
                  Hours saved each week for managers
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Fact;
